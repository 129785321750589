import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import Seo from '../components/Seo'
import '../styles/szablon_globalny.css'

export default function Error404() {
  return (
    <div className='error_box'>

      <Seo 
        title="Strona nie istnieje"
        description="Strona błędu 404."
      />

      <div className='error_grid'>
        <div className='error_txt'>
          <h1>ups... wygląda na to, że to miejsce nie istnieje</h1>
          <span>wróć do <Link to="/">strony głównej</Link></span>
        </div>
        <div className='error_img'>
          <StaticImage src="../images/404.png" alt="Trening medyczny z fizjoterapeutą" />
        </div>
      </div>
    </div>
  )
}